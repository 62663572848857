:root {
  ---primary-color: #3E4F87;
  --secondary-color: #F5F5F5;
  --white-color: #FFF;
  --black-color: #000;
  --gray-color: #808080;
  --dark-gray-color: #545454;
}

/* In Variables.css or another CSS file */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure the container takes full viewport height */
}

.app-image {
  display: none;
}

.homepage-container{
  position: absolute ;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index:  1000;
  background-Color: white; 
}

.home-banner{
  margin: 10
}

.search-container{
  top: 10px;
  position: absolute;
  left: 11.5%;
  border-radius: 100;
  z-index: 1000;
  width: 85%;
  pointer-events: auto
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.static-page-container{
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 50px;

  height: 100vh;
  overflow: auto;
}


.tooltip-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

@media (min-width: 768px) {
  .app-container {
    flex-direction: row;
  }

  .homepage-container{
    /* left: 50%; */
    right:50%;
    /* padding: 15px */
  }

  .app-image {
    display: block;
    width: 50%;
    height: 100%; /* Make sure the image container takes full height */
  }

  .app-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container without stretching */
  }

  .app-content {
    width: 50%;
    height: 100%; /* Ensure the content takes full height */
  }

  .home-banner{
    display: none;
  }

  .search-container{
  
  left: 4%;
  width: 45%;
}
}


.dropdown-container {
  position: relative;
  display: inline-block;
  z-index: 555555;
}

.dropdown-button {
  background-color: #007bff; /* Replace with your primary color */
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 200px;
  padding: 12px 16px;
  border-radius: 5px;
}

.dropdown-content.show {
  display: block;
}

.dropdown-item {
  padding: 8px 0;
}




/* BookingStatusComponent.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup button {
  margin: 5px;
}


