.custom-container {
  padding: 25px 13px 13px 13px;
  height: 100vh;
  background-color: var(--secondary-color);
}

.subContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 25px;
  background-color: #f5f5f5;
}

/* Hide scrollbar of the duration section */
::-webkit-scrollbar {
  display: none;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}