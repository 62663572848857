.DemoContentModal {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.heading {
  padding-left: 20px;
  padding-right: 20px;
}
.estimated-container{
  margin:20px;
  border-width: 2px;
  border-style: solid;
  border-color: #f0f0f0;
  padding: 14px;
  border-radius: 10px;
}
.excessChargeText{
  font-size: 12px;
  margin-top: 5px;
  color: gray;
  width: 80%;
}

.bold-text{
  font-size: 14px;
  font-weight: 700;
}