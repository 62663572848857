.feedback-container {
  /* width: 400px;
  margin: 0 auto; */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feedback-title {
  font-size: 18px;
  text-align: center;
  margin: 10px 0;
  font-weight: 600;
}

.feedback-subtitle {
  font-size: 12px;
  text-align: center;
  color: #484646;
}

.feedback-textarea-container {
  margin: 20px 0;
}

.feedback-textarea {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.feedback-buttons {
  display: flex;
  justify-content: space-between;

}

.feedback-button {
  padding: 10px 20px;
  background-color: #3390de;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.feedback-button:hover {
  background-color: #0f81de;
}
